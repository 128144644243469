import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { newsletterSuscripcionAdd } from "../redux/newsletter/actions";
import Tools from "../classes/tools";

const AlquilaTabla = ({ newsletterSuscripcionAdd, newsletterSuscripcionAddDone, s }) => {
	const intl = useIntl();

	const [newsletterEmail, setNewsletterEmail] = useState("");

	useEffect(() => {
		if (newsletterSuscripcionAddDone) setNewsletterEmail("");
	}, [newsletterSuscripcionAddDone]);

	const handleNewsletterSuscripcionAdd = (e) => {
		e.preventDefault();
		newsletterSuscripcionAdd(intl.locale, {
			email: newsletterEmail,
		});
	};

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="rent-board">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.alquilaunatabla?.title)} />
					<main className="page">
						<article className="bg-img">
							<h1>
								{intl.locale === "es" && (
									<>
										<span className="red-bg white-text prequel-font">Alquila</span>
										<span className="red-bg white-text prequel-font">una tabla</span>
									</>
								)}
								{intl.locale !== "es" && (
									<>
										<span className="red-bg white-text prequel-font">Rent</span>
										<span className="red-bg white-text prequel-font">a board</span>
									</>
								)}
							</h1>

							<picture>
								<source srcset="/img/bg/rent-board-desktop.jpg" media="(min-width: 768px)" />
								<img srcset="/img/bg/rent-board.jpg" alt="" />
							</picture>
						</article>

						<article className="newsletter box margin-auto">
							{intl.locale === "es" && (
								<>
									<h3 className="prequel-font red-text">Proximamente!</h3>
									<p>
										Actualmente estamos buscando renters para que puedan poner a disposición parte de sus
										tablas. <b>A partir del 1 de Julio</b>, lanzaremos la plataforma donde podrás elegir la
										zona que te interese, visualizar todas las tablas disponibles y reservar contactando
										directamente con el Renter. Si quieres que te avisemos cuando esté disponible, déjanos tu
										mail y te lo comunicaremos.
									</p>
								</>
							)}

							{intl.locale !== "es" && (
								<>
									<h3 className="prequel-font red-text">Coming soon!</h3>
									<p>
										Actualmente estamos buscando renters para que puedan poner a disposición parte de sus
										tablas. A partir del 1 de Julio, lanzaremos la plataforma donde podrás elegir la zona que
										te interese, visualizar todas las tablas disponibles y reservar contactando directamente
										con el Renter. Si quieres que te avisemos cuando esté disponible, déjanos tu mail y te lo
										comunicaremos.
									</p>
								</>
							)}
							<form onSubmit={handleNewsletterSuscripcionAdd}>
								<input
									className="input black-text-03"
									placeholder={Tools.convertString(s.footer?.email)}
									name="newsletter"
									id="newsletter"
									type="text"
									value={newsletterEmail}
									onChange={(e) => setNewsletterEmail(e.target.value)}
								/>
								<button type="submit" className="button black-bg-03 white-text">
									{Tools.convertString(s.footer?.enviar)}
								</button>
							</form>
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { newsletterSuscripcionAddDone } = state.app.events;
	return {
		newsletterSuscripcionAddDone,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	newsletterSuscripcionAdd,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlquilaTabla);
